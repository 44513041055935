/* src/components/Introduction/Content/content.css */

.content-body {
  width: 70%;
}

/* Settings for socials buttons */
.btn-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5%;
}

.btn i {
  color: white;
}

.btn:hover i {
  color: white;
}

.btn-text {
  margin-left: 5px;
}

/* Mobile query */
@media only screen and (max-width: 600px) {
  .content-body {
    width: 100%;
  }
}
