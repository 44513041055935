/* src/components/About/about.css */

/* combined container of bio & photo */
.about-container {
  display: flex;
  justify-content: space-between;
}

/* bio text on left side */
.about-bio-text {
  max-width: 50%;
}

/* pic settings */
.portrait-container {
  display: flex;
  justify-content: center;
  max-height: 30rem;
}

.portrait {
  max-width: 100%;
  object-fit: contain;
  margin-bottom: 2rem;
}

/* tech settings */
.tech-row-container {
  display: flex;
  justify-content: space-evenly;
}

@media only screen and (max-width: 600px) {
  .about-container {
    flex-direction: column-reverse;
  }

  .about-bio-text {
    max-width: 100%;
  }

  .portrait-container {
    align-items: center;
    max-height: none;
  }

  .tech-row-container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0.15rem;
  }
}
