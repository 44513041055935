/* src/components/Footer/footer.css */

/* Sticky Footer Classes */

html,
body {
  height: 100%;
}

.footer-text {
  color: white;
}

.footer-text:hover {
  color: white;
}
