* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

main {
  max-width: 70%;
  margin: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

main > * {
  display: block;
  margin-bottom: 2rem;
}

@media only screen and (max-width: 600px) {
  main {
    max-width: 90%;
    display: flex;
    flex-direction: column;
  }
}
